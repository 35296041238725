import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { DirectoryOfServicesLayout } from '../components/layouts'

class DirectoryOfServicesTemplate extends React.Component {
    render() {
        const site = get(this.props, 'data.site.siteMetadata')
        const page = get(this.props, 'data.contentfulPageDirectoryOfServices')
        const location = get(this.props, 'location')
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)

        return <DirectoryOfServicesLayout site={site} page={page} sitemap={sitemap} location={location} />
    }
}

export default DirectoryOfServicesTemplate

export const pageQuery = graphql`
    query DirectoryOfServicesBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                description
                siteUrl
                twitterHandle
            }
        }
        contentfulPageDirectoryOfServices(slug: { eq: $slug }) {
            __typename
            title
            slug
            userSection
            description {
                description
            }
            links {
                __typename
                ... on ContentfulPageGeneric {
                    id
                    slug
                    userSection
                    title
                    layout {
                        layout
                    }
                }
                ... on ContentfulPageGroup {
                    id
                    slug
                    userSection
                    title
                }
                ... on ContentfulPageGuideline {
                    id
                    slug
                    title
                }
                ... on ContentfulPageWidgets {
                    id
                    slug
                    userSection
                    title
                }
                ... on ContentfulWidgetLink {
                    id
                    text
                    url
                }
            }
        }
        allContentfulWidgetSitemap(filter:{title: {ne: "Don't delete - sitemap"}}) {
            totalCount
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageCustom {
                            slug
                            title
                            contentful_id
                            userSection
                        }
                        ... on ContentfulPageDirectoryOfServices {
                            id
                            title
                        }
                        ... on ContentfulPageDonation {
                            slug
                            title
                            contentful_id
                        }
                        ... on ContentfulPageGeneric {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroup {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGuideline {
                            title
                            slug
                        }
                        ... on ContentfulPageWidgets {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
